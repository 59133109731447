<template>
  <div class="d-flex highlight-home">
    <splide
      v-if="activeList.length"
      :options="options"
      @splide:pagination:mounted="() => onPaginationMounted()"
      @splide:arrows:updated="onArrowUpdated"
    >
      <splide-slide
        v-for="item in activeList"
        :key="item.id"
      >
        <div
          class="highlight-home__parallelogram"
          :style="{backgroundImage: `url(${item.pic})`}"
          @click="onSlideClick(item.id)"
        >
          <div v-html="breakLineHandle(item.title)" :data-id="item.id" class="font-bold highlight-home__item-text"></div>
        </div>
      </splide-slide>
    </splide>
    <TouchTip />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import TouchTip from '@/components/common/TouchTip'

export default {
  name: 'HighlightHome',
  components: {
    Splide,
    SplideSlide,
    TouchTip
  },
  data () {
    return {
      options: {
        perPage: 2,
        perMove: 1,
        speed: 1200,
        width: '100%',
        flickPower: 100,
        classes: {
          prev: 'splide__arrow--prev highlight-home-prev',
          next: 'splide__arrow--next highlight-home-next',
          pagination: 'splide__pagination highlight-home-pagination',
          page: 'splide__pagination__page highlight-home-page'
        }
      }
    }
  },
  computed: {
    ...mapState('lang', ['lang']),
    ...mapState('highlight', ['highlight', 'highlight_en']),
    highlightTitleMap () {
      if (!this.highlight.length) return new Map([])

      return new Map(this.highlight.map(item => [item.id, item.title]))
    },
    highlightENTitleMap () {
      if (!this.highlight_en.length) return new Map([])

      return new Map(this.highlight_en.map(item => [item.id, item.title]))
    },
    activeList () {
      return this.lang === 'hk' ? this.highlight : this.highlight_en
    }
  },
  watch: {
    lang: {
      handler () {
        const titleList = document.querySelectorAll('.highlight-home__item-text')
        if (this.lang === 'hk') {
          titleList.forEach(item => {
            const title = this.highlightTitleMap.get(parseInt(item.dataset.id))
            item.textContent = title
          })
        } else {
          titleList.forEach(item => {
            const title = this.highlightENTitleMap.get(parseInt(item.dataset.id))
            item.textContent = title
          })
        }
      }
    }
  },
  created () {
    this.fetchHighlight()
    this.fetchBigScreenPhase({ current: '02', current_id: '', lang: this.lang })
  },
  methods: {
    ...mapMutations('breadcrumb', ['UPDATE_HIGHLIGHT_PRODUCT', 'UPDATE_HIGHLIGHT_PRODUCT_EN']),
    ...mapActions('api', ['fetchHighlight', 'fetchBigScreenPhase']),
    ...mapActions('gtagEvents', ['highlightOptionTrack']),
    onSlideClick (id) {
      const name = this.highlight.find(item => item.id === id).title
      const nameEN = this.highlight_en.find(item => item.id === id).title

      this.UPDATE_HIGHLIGHT_PRODUCT(name)
      this.UPDATE_HIGHLIGHT_PRODUCT_EN(nameEN)
      const gaCode = this.highlight_en.find(item => item.id === id).ga_code
      this.highlightOptionTrack({ gaCode, lan: this.lang })
      this.$router.push({ name: 'HighlightProduct', params: { productId: id } })
    },
    onPaginationMounted () {
      this.$nextTick(() => {
        const list = document.querySelectorAll('.highlight-home-page')
        list.forEach((item, idx) => {
          item.textContent = idx + 1
        })
      })
    },
    onArrowUpdated (splide, prev, next, prevIndex, nextIndex) {
      if (prevIndex === -1) {
        prev.style.display = 'none'
      } else {
        prev.style.removeProperty('display')
      }

      if (nextIndex === -1) {
        next.style.display = 'none'
      } else {
        next.style.removeProperty('display')
      }
    },
    breakLineHandle (text) {
      return text.replaceAll('\n', '<br />')
    }
  }
}
</script>

<style lang="scss" scoped>
.highlight-home {
  padding: 0 5.73vw;
  height: 100%;

  &__parallelogram {
    width: 100%;
    height: 100%;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    @include bg-cover;
    padding-top: 21.0185vh;
  }

  &__item-text {
    font-size: 3.64583vw;
    letter-spacing: -0.073vw;
    color: $white;
    text-align: center;
    max-width: 23.4375vw;
    line-height: normal;
    margin: 0 auto;
    transform: translateX(10%);
  }

  .splide__slide {
    width: 34.896vw;
    height: 80.5556vh;
  }

  &::v-deep .is-active {
    transform: scale(1);
    opacity: 1;
  }
}

::v-deep .highlight-home-prev {
  width: 2.916667vw;
  height: 8.7963vh;
  opacity: 1;
  background: none;
  border-radius: 0;
  background-image: url('~@/assets/img/highlight/scroll-left.svg');
  left: -2.5vw;

  svg {
    display: none;
  }
}

::v-deep .highlight-home-next {
  width: 2.916667vw;
  height: 8.7963vh;
  opacity: 1;
  background: none;
  border-radius: 0;
  background-image: url('~@/assets/img/highlight/scroll.svg');
  right: -2.5vw;

  svg {
    display: none;
  }
}

::v-deep .highlight-home-pagination {
  width: auto;
  transform: translateX(0);
  left: auto;
  right: -3.3333vw;
  bottom: 7.4074vh;

  & > li {
    margin-right: 1.5625vw;

    &:last-child {
      margin-right: 0;
    }
  }
}

::v-deep .highlight-home-page {
  width: 2.604vw;
  height: 2.604vw;
  border: 2px solid $black;
  opacity: 0.3;
  background: transparent;
  border-radius: 0;
  font-size: 1.458333vw;
  box-shadow: none;
}
</style>
